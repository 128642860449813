<template>
  <div class="container-fluid px-xl-4 py-4">
    <div class="add-vehicle">
        <div class="row mobile-hide">
          <div class="col-md-6 col-6">
            <p class="hint">Select the parts you frequently deal with when adding a car to your inventory. Your
              preferred list will appear in the selected parts section. Save it when done. <span>(Mobile users, please
                scroll to the bottom of the page)</span></p>
          </div>
          <div class="col-md-6 col-6">
            <p class="hint min-hint">You can reorganize the order sequence of your preferred parts by pressing and holding the
              part and then drag it up or down.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6">
            <p class="hint" style="display: none;">Select the parts you frequently deal with when adding a car to your inventory. Your
              preferred list will appear in the selected parts section. Save it when done. <span>(Mobile users, please
                scroll to the bottom of the page)</span></p>
            <h5 class="total-parts">All Parts <span>{{ list.length }}</span></h5>
            <draggable class="list-group" tag="ul" v-model="list" v-bind="dragOptions" :move="onMove"
              @start="isDragging = true" @end="isDragging = false" :sort="false" handle=".handle">
              <transition-group type="transition" :name="'flip-list'">
                <li class="list-group-item" v-for="(element, idx) in list" :key="element.order">
                  {{ element.Description }}
                  <span class="add icon-plus-minus" @click="addAt(element, idx)">
                    +
                  </span>
                </li>
              </transition-group>
            </draggable>
          </div>
          <div class="col-md-6 col-6">
            <p class="hint min-hint" style="display: none;">You can reorganize the order sequence of your preferred parts by pressing and holding the
              part and then drag it up or down.</p>
            <h5 class="total-parts">All Parts <span>{{ list2.length }}</span></h5>
            <draggable element="span" v-model="list2" v-bind="dragOptions" :move="onMove">
              <transition-group name="no" class="list-group" tag="ul">
                <li class="list-group-item" v-for="(element, idx) in list2" :key="element.order">
                  {{ element.Description }}

                  <span class="remove icon-plus-minus" @click="removeAt(element, idx)">
                    -
                  </span>
                </li>
              </transition-group>
            </draggable>
            <button class="btn custom-btn pattern-btn mt-3 float-right" @click="save()"><span>Save Preferred list</span></button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { showSuccess, showFailure } from "../eventbus/action.js";
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import draggable from "vuedraggable";
const message = [
  "vue.draggable",
  "draggable",
  "component",
  "for",
  "vue.js 2.0",
  "based",
  "on",
  "Sortablejs"
];

export default {
  components: {
    draggable
  },
  data() {
    return {
      list: [],
      list2: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,
    };
  },
  computed: {
    ...mapGetters({
      pType: "partlist/getPType",
      selectedParts: "partlist/getSelectedParts",
    }),

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  },
  destroyed() {
    $("li.car-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },
  mounted() {
    this.getAllPartList();
    setTimeout(() => {
      $("li.car-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);
  },
  methods: {
    ...mapActions({
      getPType: "partlist/getPType",
      getSelectedPart: "partlist/getSelectedPart",
      updateSelectedParts: "partlist/updateSelectedParts",
    }),

    addAt(element, idx) {
      this.list.splice(idx, 1);
      // this.list2.push(element);
      this.list2.splice(element.id, 0, element);
    },

    removeAt(element, idx) {
      this.list2.splice(idx, 1);
      this.list.splice(element.id, 0, element);
    },

    orderList() {
      this.list = this.list.sort((one, two) => {
        return one.order - two.order;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },

    async getAllPartList() {
      try {
        this.$store.commit("setLoader", true);
        await this.getPType();
        this.list = this.pType.map((part, index) => {
          return { Description: part.Description, order: part.id, PartType: part.PartType, id: part.id };
        });
        await this.getSelectedPart();
        if (this.selectedParts.length > 0) {
          var allSelectedPartsArray = this.selectedParts.map(function (part) {
            return part.Description;
          });
          this.list2 = this.selectedParts.map((part, index) => {
            var partInfo = this.list.find(function (i, index) { return (i.Description == part.Description); });
            return { Description: part.Description, order: partInfo.id, PartType: part.PartType, id: partInfo.id };
          });
          this.list = this.list.filter(function (part, index) {
            return allSelectedPartsArray.indexOf(part.Description) == -1;
          });
        }

      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    changeRouter() {
      this.$router.push({ name: "Car list" });
    },

    async save() {
      try {
        this.$store.commit("setLoader", true);
        await this.updateSelectedParts({ selected_parts: this.list2 });
        showSuccess("Part Updated Successfully");
        // this.changeRouter();
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
<style scoped>
#msg {
  color: brown;
}
</style>
<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: move;
}



.close {
  opacity: 1;
  font-size: 1.2rem;
  cursor: pointer;
}
</style>